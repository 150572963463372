import React from 'react';
import { bool, oneOfType, object, string, shape, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { IconSuccess, NamedLink, NamedRedirect } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.module.css';
import { ArrowLeftOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';

export const ActionBarMaybe = props => {
  const {
    rootClassName,
    className,
    isOwnListing,
    listing,
    editParams,
    showNoPayoutDetailsSet,
    currentUser,
    approveListing,
    history
  } = props;
  const handleApproveListing = (listingId) => {
    return approveListing(listingId).then(res => {
      history?.push(
        createResourceLocatorString(
          'AdminListingPage',
          routeConfiguration,
          {}
        )
      );
    })
  }
  const routeConfiguration = useRouteConfiguration();

  const classes = classNames(rootClassName || css.actionBar, className);
  var isAdmin = currentUser?.attributes?.profile?.publicData?.isAdmin

  const state = listing?.attributes.state;
  const listingId = listing?.id?.uuid;
  // const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isPendingApproval = listing?.attributes?.publicData?.isApprovedListingUpdate === false;

  if (isOwnListing && showNoPayoutDetailsSet) {
    return (
      <div className={classes}>
        <p className={classNames(css.ownListingText, css.missingPayoutDetailsText)}>
          <FormattedMessage id="ListingPage.addPayoutDetailsMessage" />
        </p>
        <NamedLink className={css.addPayoutDetails} name="StripePayoutPage">
          <EditIcon className={css.editIcon} />
          <FormattedMessage id="ListingPage.addPayoutDetails" />
        </NamedLink>
      </div>
    );
  } else if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
      ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
      ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    return (
      <div className={classes}>
        <p className={ownListingTextClasses}>
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams}>
          <EditIcon className={css.editIcon} />
          <FormattedMessage id={message} />
        </NamedLink>
      </div>
    );
  } else if (isClosed) {
    return (
      <div className={classes}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }
  if (isAdmin) {
    return (
      <div className={classes}>
        <NamedLink className={css.addPayoutDetails} name="AdminListingPage" >
          <Button className={css.buttonMarketplace} >
            <ArrowLeftOutlined></ArrowLeftOutlined>
            <FormattedMessage id="LimitedAccessBanner.back" />
          </Button>
        </NamedLink>
        <p className={classNames(css.ownListingText, css.missingPayoutDetailsText)}>
          <p className={css.text}>
            <FormattedMessage id="LimitedAccessBanner.listingApprove" />
          </p>
        </p>
        <span className={css.addPayoutDetails} >
          <Button type="primary" className={css.buttonMarketplace} onClick={() => {
            handleApproveListing(listingId)
          }
          } ><CheckCircleFilled></CheckCircleFilled> <FormattedMessage id="LimitedAccessBanner.approve" /></Button>
        </span>
      </div >
    )
  }
  return null;
};
ActionBarMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  approveListing: null
};

ActionBarMaybe.propTypes = {
  rootClassName: string,
  className: string,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: shape({
    id: string,
    slug: string,
    type: string,
    tab: string,
  }),
  currentUser: string,
  approveListing: func.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
